import { bwicDateFilterOptions } from '../constants';
import { manageTokenActions, ManageTokenActionsType } from '../actions/manage-tokens.actions';
import { getType } from 'typesafe-actions';
import { manageTokensFiltersReducer } from './manage-tokens-filter.reducer';
import { RequestState } from '../constants/request-state';
import { ManageTokenFilterState, ManageTokenState } from '../types/state/ManageTokenState';
import { ApiTokenStatus } from '../types/management/AccessToken';

const initialFilter: ManageTokenFilterState = {
    customDateRange: { from: null, to: null },
    selectedDateOption: bwicDateFilterOptions.unspecified,
    companies: [],
    blotterApiReader: false,
    bwicMonitorApiReader: false,
    bwicMonitorApiHistoryReader: false,
    issuanceMonitorApiReader: false,
    issuanceMonitorApiHistoryReader: false,
    dashboardApiReader: false,
    portfolioApiReaderWriter: false,
    evalApiReader: false,
    active: false,
    expired: false,
    revoked: false,
    searchTerm: ''
};

const initialState: ManageTokenState = {
    tokens: [],
    initialFilter,
    lastAppliedTokenFilter: { ...initialFilter },
    filter: { ...initialFilter },
    companies: [],
    requestState: RequestState.none,
    companyRequestState: RequestState.none,
    generateRequestStatus: RequestState.none,
    editRequestStatus: RequestState.none,
    revokeTokenRequestStatus: {},
};

export const manageTokenReducer = (state = initialState, action: ManageTokenActionsType): ManageTokenState => {
    switch (action.type) {
        case getType(manageTokenActions.storeTokens):
            return {
                ...state,
                tokens: action.payload.tokens
            };
        case getType(manageTokenActions.setTokenRequestStatus):
            return {
                ...state,
                requestState: action.payload.requestStatus,
            }
        case getType(manageTokenActions.storeCompanies):
            return {
                ...state,
                companies: action.payload.companies
            };
        case getType(manageTokenActions.setCompanyRequestStatus):
            return {
                ...state,
                companyRequestState: action.payload.requestStatus,
            };
        case getType(manageTokenActions.storeGeneratedToken):
            return {
                ...state,
                generatedToken: action.payload.token,
                tokens: action.payload.token ? state.tokens.concat(action.payload.token) : state.tokens
            };
        case getType(manageTokenActions.setGenerateRequestStatus):
            return {
                ...state,
                generateRequestStatus: action.payload.requestStatus,
            };
        case getType(manageTokenActions.setUpdateTokenRequestStatus):
            return {
                ...state,
                editRequestStatus: action.payload.requestStatus,
            };
        case getType(manageTokenActions.tokenUpdated):
            return {
                ...state,
                tokens: state.tokens.map(t => t.id === action.payload.token.id ? action.payload.token : t)
            };
        case getType(manageTokenActions.setRevokeTokenRequestStatus):
            return {
                ...state,
                revokeTokenRequestStatus: {
                    ...state.revokeTokenRequestStatus,
                    [action.payload.tokenId]: action.payload.status
                }
            }
        case getType(manageTokenActions.tokenRevoked):
            return {
                ...state,
                tokens: state.tokens.map(t => t.id === action.payload.tokenId
                    ? { ...t, revokeDate: new Date(), status: ApiTokenStatus.Revoked }
                    : t
                )
            };
        case getType(manageTokenActions.applyTokenFilters):
            return {
                ...state,
                lastAppliedTokenFilter: state.filter
            };
        case getType(manageTokenActions.resetTokenFilters):
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    searchTerm: state.filter.searchTerm
                },
                lastAppliedTokenFilter: {
                    ...initialState.lastAppliedTokenFilter,
                    searchTerm: state.lastAppliedTokenFilter.searchTerm
                }
            };
        case getType(manageTokenActions.setSearchTerm):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
                lastAppliedTokenFilter: state.filter
            };
        case getType(manageTokenActions.reset):
            return { ...initialState };
        case getType(manageTokenActions.toogleFilterStatusActive):
        case getType(manageTokenActions.toogleFilterStatusExpired):
        case getType(manageTokenActions.toogleFilterStatusRevoked):
        case getType(manageTokenActions.toogleFilterApiBlotterApiReader):
        case getType(manageTokenActions.toogleFilterApiBwicMonitorApiReader):
        case getType(manageTokenActions.toogleFilterApiIssuanceMonitorApiReader):
        case getType(manageTokenActions.toogleFilterApiPortfolioApi):
        case getType(manageTokenActions.toogleFilterApiEvalApiReader):
        case getType(manageTokenActions.toogleFilterApiDashboardApiReader):
        case getType(manageTokenActions.setFilterCompanies):
        case getType(manageTokenActions.toogleFilterCompanies):
        case getType(manageTokenActions.setDateFilterChange):
        case getType(manageTokenActions.setDateFilterRangeChange):
            return {
                ...state,
                filter: manageTokensFiltersReducer(state.filter, action)
            }

        default:
            return state;
    }
}
