import { getType } from 'typesafe-actions';
import { xor } from 'lodash';
import { ManageTokenFilterState } from '../types/state/ManageTokenState';
import { manageTokenActions, ManageTokenActionsType } from '../actions/manage-tokens.actions';

export const manageTokensFiltersReducer = (state: ManageTokenFilterState, action: ManageTokenActionsType) => {
    switch (action.type) {
        case getType(manageTokenActions.toogleFilterStatusActive):
            return {
                ...state,
                active: !state.active,
            }
        case getType(manageTokenActions.toogleFilterStatusExpired):
            return {
                ...state,
                expired: !state.expired
            }
        case getType(manageTokenActions.toogleFilterStatusRevoked):
            return {
                ...state,
                revoked: !state.revoked
            }
        case getType(manageTokenActions.toogleFilterApiBlotterApiReader):
            return {
                ...state,
                blotterApiReader: !state.blotterApiReader
            }
        case getType(manageTokenActions.toogleFilterApiBwicMonitorApiReader):
            return {
                ...state,
                bwicMonitorApiReader: !state.bwicMonitorApiReader,
                bwicMonitorApiHistoryReader: !state.bwicMonitorApiReader
            }
        case getType(manageTokenActions.toogleFilterApiIssuanceMonitorApiReader):
            return {
                ...state,
                issuanceMonitorApiReader: !state.issuanceMonitorApiReader,
                issuanceMonitorApiHistoryReader: !state.issuanceMonitorApiReader
            }
        case getType(manageTokenActions.toogleFilterApiPortfolioApi):
            return {
                ...state,
                portfolioApiReaderWriter: !state.portfolioApiReaderWriter,
            }
        case getType(manageTokenActions.toogleFilterApiEvalApiReader):
            return {
                ...state,
                evalApiReader: !state.evalApiReader
            }
        case getType(manageTokenActions.toogleFilterApiDashboardApiReader):
            return {
                ...state,
                dashboardApiReader: !state.dashboardApiReader
            }
        case getType(manageTokenActions.setFilterCompanies):
            return {
                ...state,
                companies: action.payload.companies
            }
        case getType(manageTokenActions.toogleFilterCompanies):
            return {
                ...state,
                companies: xor(action.payload.companies, state.companies)
            }
        case getType(manageTokenActions.setDateFilterChange):
            return {
                ...state,
                selectedDateOption: action.payload.dateOption,
            }
        case getType(manageTokenActions.setDateFilterRangeChange):
            return {
                ...state,
                customDateRange: action.payload.dateRange,
            }
        default:
            return state;
    }
}
